




























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PasmoAppDownloadPanel extends Vue {
  @Prop()
  readonly isHiddenCaution?: boolean;
}
