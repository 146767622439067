

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Slick from 'vue-slick';
import PasmoAppDownloadPanel from '@/components/PasmoAppDownloadPanel.vue';

@Component({
  components: {
    Slick,
    PasmoAppDownloadPanel
  }
})
export default class P0908 extends Vue {
  tabStatus: string = 'mobile';
  isSlickReady: boolean = true;

  readonly slickOptions = {
    dots: true,
    dotsClass: 'pCharSlide_dots',
    infinite: true,
    autoplay: false,
    autoplaySpeed: 6000,
    speed: 500,
    arrow: true,
    prevArrow:
      '<button type="button" class="pCharSlide_btn pCharSlide_btn-prev" aria-label="前へ"></button>',
    nextArrow:
      '<button type="button" class="pCharSlide_btn pCharSlide_btn-next" aria-label="次へ"></button>'
  };

  //QA表示・非表示トグル動作（DOMを直接操作している）
  toggleAnswer(e: Event) {
    let target = e.target as HTMLElement;
    let targetParent = target.parentNode as HTMLElement;
    let targetBox = targetParent.nextSibling as HTMLElement;
    if (target.getAttribute('aria-expanded') === 'true') {
      target.setAttribute('aria-expanded', 'false');
      targetBox.setAttribute('aria-hidden', 'true');
    } else {
      target.setAttribute('aria-expanded', 'true');
      targetBox.setAttribute('aria-hidden', 'false');
    }
  }

  clickLogin() {
    this.$auth.loginWithRedirect({
      appState: {
        targetUrl:
          '/odakyu-point?tab=pasmo-charge&pasmo_charge_tab=info-pasmo-charge'
      }
    });
  }
}
